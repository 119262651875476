const YoutubeNew = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM22.8379 10.2344C23.5898 10.4355 24.1836 11.0293 24.3848 11.7832C24.75 13.1484 24.75 16 24.75 16C24.75 16 24.75 18.8516 24.3848 20.2168C24.1836 20.9707 23.5918 21.5645 22.8379 21.7656C21.4727 22.1328 16 22.1328 16 22.1328C16 22.1328 10.5273 22.1328 9.16211 21.7656C8.41016 21.5645 7.81641 20.9707 7.61523 20.2168C7.25 18.8516 7.25 16 7.25 16C7.25 16 7.25 13.1484 7.61523 11.7812C7.81641 11.0273 8.4082 10.4336 9.16211 10.2324C10.5273 9.86719 16 9.86719 16 9.86719C16 9.86719 21.4727 9.86719 22.8379 10.2344ZM18.793 15.9805L14.2617 13.3828V18.6172L18.793 15.9805Z"
        fill="white"
      />
    </svg>
  )
}

export default YoutubeNew
